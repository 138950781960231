<template>
  <div class="account">
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>系统管理</el-breadcrumb-item>
          <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- content -->
      <div class="accountMain">
          <!-- table top -->
        <div class="accTableTop">
          <div class="top-left">
              <div class="accCreate" @click="cerateNew">创建账户</div>
              <div class="gray" v-if="isDisable">{{statusV}}</div>
              <div class="accCreate" v-if="!isDisable" @click="changgeStatus">{{statusV}}</div>
              <el-dropdown trigger="click" @command="selectAccStatus">
                  <div class="accCreate" type="primary">
                    账号状态<i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="null">全部</el-dropdown-item>
                    <el-dropdown-item command="启用">启用</el-dropdown-item>
                    <el-dropdown-item command="禁用">禁用</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
              <!-- 绑定机构 -->
              <div class="gray" v-if="isDisable">绑定机构</div>
              <div class="accCreate" v-if="!isDisable" @click="bindOrg">绑定机构</div>
              <div class="gray" v-if="isDisable">取消绑定机构</div>
              <div class="accCreate" v-if="!isDisable" @click="cancelBindOrg">取消绑定机构</div>
          </div>
          <div class="top-right">
              <div class="accSearch">
              <el-input
                placeholder="请输入内容"
                prefix-icon="el-icon-search"
                @change="allSearch"
                class="elSerch"
                v-model="accAllSearch">
                <span slot="append" style="background-color: #00CCCC">搜索</span>
              </el-input>
              </div>
          </div>
        </div>
        <!-- table -->
        <WebUserTable ref="childrene"/>
      </div>
      <!-- 用户绑定机构选机构弹框 -->
      <el-dialog
          :visible.sync="selectBox"
          width="34%"
          center>
            <span style="margin-right:20px">选择检测机构:</span>
            <el-select v-model="value" filterable clearable placeholder="请选择" @focus="slectOrg" @clear="clear">
              <el-option
                v-for="(item,index) in orgList"
                :key="index"
                :label="item.orgName"
                :value="item.id">
              </el-option>
            </el-select>
 

          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelSelect">取 消</el-button>
            <el-button type="primary" @click="sureSelect">确 定</el-button>
          </span>
        </el-dialog>
  </div>
</template>
<script>
import WebUserTable from '@/components/table/webUserTable.vue'
import {getOrgList} from '@/api/orgController.js'
import {enableUser,disableUser,bindORG,cancelBindORG} from '@/api/accountController.js'

export default {
  beforeRouteLeave(to,from,next){
    from.meta.keepAlive = false;
    next();
  },
    components:{
      WebUserTable
    },
    data(){
      return{
        statusV:'禁用',//启用/禁用状态
        isDisable:true,
        accAllSearch:null,//模糊查询
        tableSelect:null,//子表的选择状态
        accStatus:null,//账号状态
        selectBox:false,//机构弹窗控件

        //机构菜单选择
        orgList: [],
        value: null,//所选择的机构信息
        pageSize: 1000,
        pageNum: 1,
        total: 0,
      }
    },

    methods:{
      //创建账号
      cerateNew(){
        this.$router.push({
          path:'/createAccount'
        })
      },
      // 改变当前状态
      changgeStatus(){
        if(this.tableSelect.enabled == "禁用"){
          var noId = this.tableSelect.id;
          enableUser({
              id:noId
            }).then(res=>{
              if(res.data.status==200){
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                location.reload();//刷新
              }
              else{
                    this.$message.error(res.data.message);
              }
            }).catch(err=>{
                  this.$message.error(err);
            })
          //禁用
        }else{
            var isId = this.tableSelect.id;
            disableUser({
              id:isId
            }).then(res=>{
              if(res.data.status==200){
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                location.reload();//刷新
              }
              else{
                    this.$message.error(res.data.message);
              }
            }).catch(err=>{
                  this.$message.error(err);
            })
        }
      },
      //账号状态
      selectAccStatus(e){
        var teme = e;
        if(teme == 'null'){
          teme = null
        }
        if(teme == "启用"){
          teme = true
        }
        if(teme == "禁用"){
          teme = false
        }
        //console.log(teme);
        this.accStatus = teme;
        this.$refs.childrene.getData(1,10,this.accStatus,null);
      },
      //模糊查询
      allSearch(){
        this.$refs.childrene.getData(1,10,null,this.accAllSearch);
      },
      
       //打开绑定机构
      bindOrg(){
        if(this.tableSelect.id != null){
          this.selectBox = true;
        }else{
          this.$message.error("请选择对应账号！");
        }
      },
      //选择机构
      slectOrg(){
         // console.log("开始选择机构");
          getOrgList({
            "currentPage":this.pageNum,
            "pageSize":this.pageSize,
          }).then(res=>{
            if(res.data.status == 200){
              const orgListF =res.data.data.records;
              const newOrgList =[];
              orgListF.forEach((curr,index)=>{
                  if(curr.orgType == "检测机构"){
                      newOrgList.push(curr);
                  }
              });
              this.orgList = newOrgList;
              this.total =res.data.data.total;
            }else{
              this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
      },
      //确定选择
      sureSelect(){
          var userId = this.tableSelect.id;
          var orgId = this.value;
          if((userId!=null)&&(orgId!=null)){
            bindORG({
              orgId:orgId,
              userId:userId
            }).then(res=>{
              if(res.data.status == 200){
                this.$message({
                  message: '绑定成功！',
                  type: 'success',
                  duration:2000,
                  onClose:()=>{
                    console.log("提示完成");
                    this.selectBox = false;
                    this.$refs.childrene.getData(1,10,null,null);//刷新
                  }
                });
               
                
              }else{
                this.$message.error(res.data.message)
              }
            }).catch(res=>{
             this.$message.error(res);
          })
          }else{
            this.$message.error("请选择对应的检测机构！")
          }
      },
      //取消选择
      cancelSelect(){
          this.selectBox = false
      },
      //解除绑定机构
      cancelBindOrg(){
        cancelBindORG({
          userId:this.tableSelect.id
        }).then(res=>{
          if(res.data.status==200){
            this.$message({
                  message: '解除绑定成功!',
                  type: 'success',
                  duration:2000,
                  onClose:()=>{
                    console.log("提示完成");
                    this.selectBox = false;
                    this.$refs.childrene.getData(1,10,null,null);//刷新
                  }
                  
              });
              
          }else{
            this.$message.error(res.data.message);
          }
        })
      },
      //清空
      clear(){
          this.value = null;
      },
    }
}
</script>

<style scoped lang="stylus">
//change elemen-ui style

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }

//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }


//冻结类
.gray 
  width: 116px 
  height 32px 
  margin-right: 24px
  background-color:#F4F4F4
  color: #AEAEAE
  line-height: 32px 
  text-align: center
  font-size: 14px
  cursor: not-allowed

//narmoal
.account  
  overflow hidden
  .accountMain
    overflow hidden
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    .accTableTop
      display: flex
      justify-content: space-between
      .top-left
        display: flex
        .accCreate 
          width: 116px 
          height 32px 
          margin-right: 24px
          background-color:#00CCCC
          cursor pointer
          color: #fff
          line-height: 32px 
          text-align: center
          font-size: 14px
      .top-right
        display: flex
        .accSearch
          height: 32px
          margin-left: 24px


</style>