<template>
  <div class="table">
    <el-table
      ref="multipleTable"
      :data="tableData"
      :highlight-current-row="true"
      tooltip-effect="dark"
      style="width: 100%; color: #858585"
      @selection-change="selectRow"
      :header-cell-style="tableHeaderCellStyle"
    >
      <el-table-column type="selection" fill="#00CCCC" fixed="left">
      </el-table-column>
      <!-- 表头开始 -->
      <el-table-column label="账户号/手机号" width="170" prop="mobile" align="center" />
      <el-table-column label="用户名" prop="username" show-overflow-tooltip align="center" />
      <el-table-column label="昵称" prop="nickName" show-overflow-tooltip align="center" />
      <el-table-column prop="orgName" label="机构名称" align="center" />
      <el-table-column prop="orgType" label="机构类型" align="center" />
      <el-table-column align="center" label="所属角色" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-for="item in scope.row.roleList" :key="item.id" style="margin-right: 10px;">{{ item.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="创建时间" show-overflow-tooltip width="160" />
      <el-table-column prop="enabled" align="center" label="状态" width="90" />
      <el-table-column align="center" label="操作" width="160">
        <template slot-scope="scope">
          <span style="color: #00cccc; cursor: pointer;margin-right: 20px;" @click="clickRowRole(scope.row)">角色分配</span>
          <span style="color: #00cccc; cursor: pointer;" @click="clickRow(scope.row)">查看详情</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page">
      <!-- left -->
      <span class="pageLeft">共 {{ count }} 条记录 第 {{ currentPage }} / {{ tatalPage }}页</span>
      <!-- right -->
      <el-pagination
        @size-change="SizeChange"
        background
        @current-change="CurrentChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="onlyPage"
        :pager-count="5"
        layout="sizes, prev, pager, next, jumper"
        :total="count"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="showRole"
      width="34%"
      center>
        <span style="margin-right:20px">分配角色:</span>
        <el-select v-model="roleValue" multiple filterable clearable placeholder="请选择" @clear="clearRole">
          <el-option
            v-for="(item,index) in roleList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>


      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSelect" style="color:#000 !important;">取 消</el-button>
        <el-button type="primary" @click="sureSelect" style="color:#fff !important;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getWebList, updateUserRole } from '@/api/accountController.js';
import { getRoleList } from '@/api/role.js'

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      // 分页相关
      currentPage: 1, // 当前页面,默认1
      tatalPage: 0, //总页数,为加载时先为0，页面首次加载后去请求即可
      count: 0, //总的条数记录
      onlyPage: 10, // 当前页面可以展示多少条数据,默认10
      roleValue: null,
      selectUser: undefined,
      roleList: [],
      showRole: false
    };
  },
  mounted() {
    //初始化获取列表数据
    this.getData(1, 10, null, null);
  },

  //监听勾选值
  watch: {
    multipleSelection: {
      handler(pre) {
        var tem = pre;
        if (tem.length == 0) {
          this.$parent.isDisable = true;
        }
        if (tem.length == 1) {
          this.$parent.isDisable = false;
          //console.log(tem);
          this.$parent.tableSelect = tem[0];
          if (tem[0].enabled == '启用') {
            this.$parent.statusV = '禁用';
          } else {
            this.$parent.statusV = '启用';
          }
        }
        if (tem.length > 1) {
          this.$parent.isDisable = true;
          this.$message({
            message: '当前仅支持下载单个数据！',
            type: 'warning'
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
      //确定选择
      sureSelect(){
          if(this.roleValue != null && this.roleValue.length != 0 && this.selectUser.id != null){
            const rLoading = this.openLoading()
            updateUserRole({
              roleIds: this.roleValue,
              userId: this.selectUser.id
            }).then(res=>{
              if(res.data.status == 200){
                this.$message({
                  message: '分配成功！',
                  type: 'success',
                  duration:2000,
                  onClose:()=>{
                    console.log("提示完成");
                    this.showRole = false;
                    rLoading.close()
                    this.getData(1,10,null,null);//刷新
                  }
                });
              }else{
                this.$message.error(res.data.message)
              }
            }).catch(res=>{
             this.$message.error(res);
          })
          }else{
            this.$message.error("请选择对应的权限！")
          }
      },
      //取消选择
      cancelSelect(){
          this.showRole = false
      },
      async clickRowRole(row){
        await this.slectRole()
        this.selectUser = row
        this.roleValue = []
        row.roleList.forEach((item,index) => {
          this.roleValue.push(item.id)
        });
        this.showRole = true
      },
      clearRole(){
        this.roleValue = null
      },
      //选择角色
      slectRole(){
        getRoleList({
          currentPage: 1,
          pageSize: 1000,
        }).then(res=>{
          if(res.data.status == 200){
            this.roleList = res.data.data.records;
          }else{
            this.$message.error(res.data.message);
          }
        }).catch(res=>{
            this.$message.error(res);
        })
      },
    //表头样式回调
    tableHeaderCellStyle() {
      return 'color:#fff;background-color:#00CCCC';
    },
    // getData
    getData(currentPage, onlyPage, accStatus, all) {
      const rLoading = this.openLoading(); // 开始loading
      getWebList({
        currentPage: currentPage,
        pageSize: onlyPage,
        enabled: accStatus,
        username: all
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.tableData = res.data.data.records;
            this.tatalPage = parseInt(res.data.data.size);
            this.count = parseInt(res.data.data.total);
            rLoading.close(); // 关闭loading
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
    // 复选框选项
    selectRow(val) {
      //console.log(this.multipleSelection);
      this.multipleSelection = val;
    },

    // 分页相关
    SizeChange(val) {
      this.onlyPage = val;
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      ); //更新数据
    },
    CurrentChange(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      );
    },
    prevClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      );
    },
    nextClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      );
    },
    clickRow(e) {
      this.$router.push({
        path: '/webUserDetail',
        query: {
          id: e.id,
          type: e.type
        }
      });
    }
  }
};
</script>
<style scoped>
.table {
  margin-top: 0.1563rem;
}
.el-button {
  color: #00a3a3 !important;
}
.action {
  display: flex;
  flex-direction: column;
}
.actionP {
  cursor: pointer;
  color: #00cccc;
  margin: 0 !important;
}
.resoult {
  text-align: left;
}
.page {
  display: flex;
  margin-top: 0.3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft {
  color: #858585;
  font-size: 0.1094rem;
  line-height: 0.1875rem;
}
.el-pagination {
  color: #858585 !important;
  font-size: 0.1094rem !important;
  line-height: 0.1875rem !important;
}
</style>
